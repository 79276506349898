<template>
	<resource-form :id="id" type="article" plural="articles" v-model="article"></resource-form>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'

export default {
	name: "ArticlesForm",
	components: {resourceForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		article: sync('articles/article'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('topics/getTopics!', null)
			if (this.isEdit) {
				this.$store.set('articles/getArticle!', this.id)
			} else {
				this.article = new models.Resource('Article')
			}
		}
	}
}
</script>